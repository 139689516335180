import React from "react";
import PropTypes from "prop-types";
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import moment from "moment";

import Roboto from "fonts/roboto/Roboto-Condensed.ttf";
import RobotoBold from "fonts/roboto/Roboto-BoldCondensed.ttf";

Font.register({
  family: "Roboto",
  fontWeight: "normal",
  src: Roboto,
});
Font.register({
  family: "Roboto",
  format: "bold",
  src: RobotoBold,
});

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },
  sidebar: {
    width: 130,
    backgroundColor: "#c97b50",
  },
  body: {
    flex: 4,
    height: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    padding: "2px 10px",
    backgroundColor: "#fff",
    alignItems: "center",
    alignContent: "space-around",
  },
  day: {
    minHeight: "10%",
    maxHeight: "23%",
    width: "47%",
    borderRadius: 8,
    marginBottom: 10,
    display: "flex",
    padding: 2,
    flexDirection: "column-reverse",
    backgroundColor: "#F6EAE5",
  },
  dayWeek: {
    width: "65%",
    height: "65%",
    borderRadius: 8,
    marginBottom: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    padding: 2,
    flexDirection: "column-reverse",
    backgroundColor: "#F6EAE5",
  },
  dayTitle: {
    color: "#D26F47",
    marginBottom: 5,
    fontSize: 14,
    fontFamily: "Roboto",
  },
  dayOptions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  dayOption: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  optionText: {
    fontFamily: "Roboto",
    fontSize: 12,
    marginTop: 0,
    paddingRight: 3,
    marginBottom: 0,
    lineHeight: 0.77,
  },
  optionOrText: {
    fontFamily: "Roboto",
    fontSize: 11,
    marginTop: 0,
    paddingHorizontal: 5,
    marginBottom: 0,
    lineHeight: 0.77,
    color: "#c97b50",
  },
  optionLabelImage: {
    width: 13,
    height: 13,
    transform: "translate(0px, 2px)"
  },
  optionDish: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: -2,
    marginLeft: 10,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  midDot: {
    position: "absolute",
    color: "#D26F47",
    left: -10,
    top: 6,
    marginLeft: 3,
    marginRight: 3,
    fontSize: 18,
    fontFamily: "Roboto",
  },
  choice: {
    marginLeft: 5,
    marginRight: 5,
  },
  logoContainer1: {
    position: "absolute",
    top: 10,
    left: 0,
    width: 130,
    height: 90,
    alignItems: "center",
    justifyContent: "center",
  },
  weekContainer: {
    position: "absolute",
    top: 120,
    left: 3,
    alignItems: "center",
  },
  weekText: {
    width: "90%",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: 13,
    color: "#fff",
    margin: 2,
  },
  repasTitle: {
    width: "90%",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: 20,
    color: "#fff",
    fontWeight: "bold",
    marginBottom: 5,
  },
  menuTitle: {
    width: "90%",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: 14,
    color: "#fff",
    fontWeight: "bold",
    marginBottom: 10,
  },
  logo: {
    width: "100%",
    objectFit: "contain",
  },
  legendContainer: {width: 130, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", marginLeft: 10, marginTop: 50},
  legendRow: {width: 150, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"},
  legendItem: {width: 130, display: "flex", flexDirection: "row", alignItems: "center", marginTop: 10},
  legendItemImage: { width: 20, height: 20, marginRight: 5},
  legendItemText: {width: 100,     fontFamily: "Roboto",  fontSize: 9, marginRight: 5, marginLeft: 5, color: "white"},
});

const WeekMenuPdf = (props) => {
  const { week, menuState, logo, ui } = props;
  const template = ui.establishment.template;

  return (
    <Document>
      {Object.entries(Object.entries(menuState)[0][1])
        .filter(([key]) => Object.keys(template).includes(key))
        .sort((a,b) => template[a[0]].heure - template[b[0]].heure)
        .map(([key], index) => (
          Object.keys(template[key].menus)
          .sort((a,b) => template[key].menus[a].weight - template[key].menus[b].weight)
          .map((_menu, indx2) => 
            <Page size="A4" orientation="landscape" key={index + " " + indx2}>
              <View style={styles.container}>
                <View style={styles.sidebar}>
                  <View style={styles.weekContainer}>
                    <Text style={styles.repasTitle}>{key}</Text>
                    {Object.keys(template[key].menus).length > 1 ?
                      <Text style={styles.menuTitle}>{_menu}</Text>
                    : null}
                    <Text style={styles.weekText}>
                      Semaine du {moment(week[0]).format("ll")} au{" "}
                      {moment(week[6]).format("ll")}
                    </Text>
                    {ui?.establishment?.labels ? Object.values(ui?.establishment?.labels).map((_label) => (
                      <View key={_label} style={styles.legendItem}>
                        <Image src={_label.img} style={styles.legendItemImage}/>
                        <Text style={styles.legendItemText}>{_label.description}</Text>
                      </View>
                    )) : null}
                  </View>
                  <View style={styles.logoContainer1}>
                    {logo && <Image src={logo} style={styles.logo} />}
                  </View>
                </View>
                {template[key].menus[_menu].type === "weekly" ?
                  <View style={styles.body}>
                      <View style={styles.dayWeek}>
                          <View style={[styles.dayOption, {display: "flex", alignItems: "center", justifyContent: "space-around"}]}>
                            {template[key].categories
                              .filter(category => Object.entries(Object.values(menuState)[0][key][category]).filter(([_id, _option]) => _option.origin === _menu).filter(([, _option]) => typeof(_option.name) === "string" && _option.name !== "").length > 0)
                              .map(category => (
                              <View style={[styles.optionDish]} key={category}>
                                <Text style={[styles.midDot, {fontSize: 25, top: 9, left: -20}]}>·</Text>
                                <View style={{height:27, marginTop: 5}}></View>
                                {/* <Text style={[styles.optionText, {fontSize: 20, color: "#555555"}]}>{category}: </Text> */}
                                {Object.entries(Object.values(menuState)[0][key][category]).filter(([_id, _option]) => _option.origin === _menu).map(([id, option], n) => (
                                  <>
                                    {n > 0 ? 
                                      <Text style={[styles.optionOrText, {fontSize: 23}]}>OU</Text>
                                      : null}
                                    <Text style={[styles.optionText, {fontSize: 25}]} key={id}>{option.name}</Text>
                                    {option.label && ui.establishment.labels[option.label]?.img ? <>
                                      <Image src={ui.establishment.labels[option.label].img} style={[styles.optionLabelImage,{width: 22, height: 22}]} />
                                    </> : null}
                                  </>
                                ))}
                              </View>
                            ))}
                          </View>
                        <Text style={[styles.dayTitle, {fontSize: 30}]}>
                          Menu de la semaine
                        </Text>
                      </View>
                  </View>
                : 
                <View style={styles.body}>
                  {Object.entries(menuState).map(([slug, data], indx) => (
                    <View style={styles.day} key={indx}>
                      <View style={styles.dayOptions}>
                        <View style={styles.dayOption}>
                          {template[key].categories
                            .filter(category => menuState[slug][key] && menuState[slug][key][category] && Object.entries(menuState[slug][key][category]).filter(([_id, _option]) => _option.origin === _menu).filter(([, _option]) => typeof(_option.name) === "string" && _option.name !== "").length > 0)
                            .map(category => (
                            <View style={styles.optionDish} key={category}>
                              <Text style={styles.midDot}>·</Text>
                              <View style={{height:20, marginTop: 5}}></View>
                              {/* <Text style={styles.optionText}>{category}: </Text> */}
                              {Object.entries(menuState[slug][key][category]).filter(([_id, _option]) => _option.origin === _menu).map(([id, option], n) => (
                                <>
                                  {n > 0 ? 
                                    <Text style={styles.optionOrText}>OU</Text>
                                    : null}
                                  <Text style={styles.optionText} key={id}>{option.name}</Text>
                                  {option.label && ui.establishment.labels[option.label]?.img ? <>
                                    <Image src={ui.establishment.labels[option.label].img} style={styles.optionLabelImage} />
                                  </> : null}
                                </>
                              ))}
                            </View>
                          ))}
                        </View>
                      </View>
                      <Text style={styles.dayTitle}>
                        {data.day.seconds ? 
                        moment(data.day.seconds * 1000).format("dddd Do MMMM").toLocaleUpperCase()
                        : 
                        moment(data.day).format("dddd Do MMMM").toLocaleUpperCase()}
                      </Text>
                    </View>
                  ))}
                </View>
              }
                
              </View>
            </Page>

          ))
        )
      }
    </Document>
  );
};
WeekMenuPdf.propTypes = {
  week: PropTypes.array.isRequired,
  menuState: PropTypes.object.isRequired,
  logo: PropTypes.string,
  ui: PropTypes.any,
};

export default WeekMenuPdf;
