import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import { Button, Spinner, DemoModal } from "../../../../../lib/components";
import { Col, Container, Row } from "../../../../../lib/components";
import { Printer, Video, Clock } from "react-feather";

import { Info } from "react-feather";
import { VideoTuto } from "../../../../../lib/components/YoutubeModal";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import frLocale from "@fullcalendar/core/locales/fr";
import interactionPlugin from "@fullcalendar/interaction";
import styles from "assets/scss/pages/animations.module.scss";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useHistory } from "react-router";
import useUI from "../../../../../hooks/ui.hook";
import useFeature from "@/hooks/useFeature";

import { Banner, EventModal, CategoriesCrud, WeekExportButton } from "../../router_components";

import useAnimationEvents, { useAnimationCategories } from "../../../../../resources/animations";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import firebase from "firebase";
const firestore = firebase.firestore;

const Animations = () => {
  const history = useHistory();
  const calendarRef = useRef(null);
  const [ui] = useUI();

  const { categories, loading: categoriesLoading } = useAnimationCategories();
  const { events, loadWeek, loading: eventsLoading } = useAnimationEvents();

  const [isOpen, setOpen] = useState(false);
  const [eventsParsed, setEventsParsed] = useState([]);
  const [eventOpen, setEventOpen] = useState(null);

  const [preselectStart, setPreselectStart] = useState(null);
  const [preselectEnd, setPreselectEnd] = useState(null);
  const [preselectDate, setPreselectDate] = useState(null);

  const [additionnalData, setAdditionnalData] = useState({}); //for week export in case of specific dev for establishment, see ekipage for example



  const isSavePublish = useFeature("savePublishAnimations");

  useEffect(() => {
    if (eventsLoading || categoriesLoading) return;

    const getEventColorFromCategory = (categoryId) => {
      if (!categoryId) return "#f2994a";
      const category = categories?.find((i) => i.uid === categoryId);
      if (!category) return "#f2994a";
      return category.color;
    };

    let data = events.map((i) => ({
      title: i.title,
      start: i.start.toDate(),
      end: i.end.toDate(),
      borderColor: getEventColorFromCategory(i.categoryId),
      backgroundColor: !i.isPublished && i.isPublished !== undefined && i.isPublished !== null ? "#b9bdc7" : getEventColorFromCategory(i.categoryId),
      image: i.image,
      extendedProps: {
        ...i,
      },
    }));
    setEventsParsed(data);
  }, [events, categories, eventsLoading, categoriesLoading]);

  const _calendarStartDay = () => {
    if (!calendarRef || !calendarRef.current) return new Date();
    let date = calendarRef.current.getApi().getDate();

    date.setDate(date.getDate() - date.getDay() + 1);
    return date;
  };

  const _weekExport = () => {
    history.push("/dashboard/animations/weekExport", {
      events: eventsParsed,
      monday: _calendarStartDay(),
      logo: ui.establishment.profileImage,
      additionnalData: additionnalData ? JSON.stringify(additionnalData) : {},
    });
  };

  const _monthExport = () => {
    history.push("/dashboard/animations/monthExport", {
      events: eventsParsed,
      monday: _calendarStartDay(),
      logo: ui.establishment.profileImage,
      additionnalData: additionnalData ? JSON.stringify(additionnalData) : {},
      categories: categories,
    });
  };

  const _onDateSelect = (e) => {
    if (e.view.type === "timeGridWeek") {
      setPreselectStart(e.start);
      setPreselectEnd(e.end);
    }
    setPreselectDate(e.start);
    setOpen(true);
  };



  //demo clignotement 

  const [demoData, setDemoData] = useState({});

  useEffect(() => {

    if (ui?.establishment?.demo === true) {
      const stop = firestore().collection("establishments").doc(ui?.user?.establishment).onSnapshot(_doc => {
        const _data = _doc.data();
        const demoData = _data?.demoData;
        setDemoData(demoData);
      });

      return () => {
        stop();
      };
    }
  }, [ui.establishment]);

  const handleCreateEvent = () => {
    setOpen(true);

    if (ui?.establishment?.demo === true && !demoData?.createEvent) {
      firestore().collection("establishments").doc(ui?.user?.establishment).update({ [`demoData.createEvent`]: true });
    }
  };

  if (eventsLoading) return <Spinner />;

  return (
    <>
      {ui?.establishment?.demo === true ?
        <DemoModal type="animation"></DemoModal>
        : null}
      <div className={styles.animation}>
        {isSavePublish && <div style={{ position: 'absolute', right: 160, marginTop: 0, display: "flex", gap: 5, cursor: "pointer", alignItems: "center" }}>
          <Clock onClick={() => history.push("/dashboard/waitingList")} color="#300438" size={32} />
          Programmation
        </div>}
        <VideoTuto url="https://youtu.be/7MZ1mRH2BV4" /*preview={animCard}*/ />

        <Container>
          <Row>
            <CategoriesCrud />
          </Row>
          <Banner value={additionnalData} onChange={setAdditionnalData} />
          <Row>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div style={{ flex: 1, display: "flex" }}>
                <Button
                  onClick={handleCreateEvent}
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  color="primaryDark"
                  blinking={ui?.establishment?.demo === true && !demoData?.createEvent ? true : false}
                >
                  créer un évènement
                </Button>
              </div>
              <div
                style={{
                  flex: 3,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <WeekExportButton callback={_weekExport} />
                <Button
                  onClick={() => _monthExport()}
                  style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    margin: "0 5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 5,
                  }}
                  color="primary"
                >
                  <Printer size={20} />
                  Impression mois
                </Button>
              </div>
            </div>
          </Row>
          <Row>
            <Col lg={12} style={{ marginTop: 20 }}>
              <div
                style={{
                  border: "1px solid lightgrey",
                  padding: 7,
                  borderRadius: 15,
                  height: 670,
                }}
              >
                <FullCalendar
                  headerToolbar={{ center: "dayGridMonth,timeGridWeek" }}
                  ref={calendarRef}
                  plugins={[dayGridPlugin, bootstrapPlugin, interactionPlugin, timeGridPlugin]}
                  themeSystem="bootstrap"
                  initialView="timeGridWeek"
                  locales={[frLocale]}
                  locale={"fr"}
                  selectable
                  select={_onDateSelect}
                  slotDuration="00:30:00"
                  slotMinTime="08:00:00"
                  slotMaxTime="23:00:00"
                  allDaySlot={false}
                  events={eventsParsed}
                  eventClick={(e) => {
                    setEventOpen(e.event.extendedProps);
                    setOpen(true);
                  }}
                  eventContent={(e) => {
                    return (
                      <>
                        <ReactTooltip id={`animation-${e.event.extendedProps.uid}`} place="top" effect="float" style={{ zIndex: 9000, opacity: 1 }}>
                          <div style={{ maxWidth: 800, fontSize: 16 }}>
                            <p>{e.event.extendedProps.title}</p>
                            <p>Horaires: {moment(e.event.extendedProps.start.toDate()).format("HH:mm")} - {moment(e.event.extendedProps.end.toDate()).format("HH:mm")}</p>
                            {e.event.extendedProps.place?.length > 0 && <p>Lieu: {e.event.extendedProps.place}</p>}
                          </div>
                        </ReactTooltip>
                        <div data-tip data-for={`animation-${e.event.extendedProps.uid}`} style={{ cursor: "pointer", overflow: "hidden", display: "inline-block", height: "100%" }}>
                          <div>{moment(e.event.extendedProps.start.toDate()).format("HH:mm")} - {moment(e.event.extendedProps.end.toDate()).format("HH:mm")}</div>
                          <div>{e.event.extendedProps.title}</div>
                        </div>
                      </>
                    );
                  }}
                  datesSet={(arg) => loadWeek(arg.start)}
                  nowIndicator
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isOpen ?
        <EventModal
          isOpen={isOpen}
          eventOpen={eventOpen}
          preselectStart={preselectStart}
          preselectEnd={preselectEnd}
          preselectDate={preselectDate}
          onClose={() => {
            setOpen(false);
            setEventOpen(null);
            setPreselectStart(null);
            setPreselectEnd(null);
            setPreselectDate(null);
          }}
          events={eventsParsed}
        />
        : <></>}
    </>
  );
};

export default Animations;
