/* eslint-disable */
import React, {useState, useEffect} from "react";
import { useHistory,useParams } from "react-router-dom";
import useUI from "hooks/ui.hook";
import { Button } from "lib/components";
import FlipBook from "./FlipBook";
import Gazette from "./gazette.pdf";

const GazetteView = ({setGazetteView}) => {

  return (
    <div style={{position: "fixed", overflow: "hidden", width: "100vw", height: "calc(100vh - 100px)"}}>
      <div style={{position: "absolute"}}>
        <Button color="primary" size="sm" style={{fontSize: "x-large"}} onClick={() => setGazetteView(false)}>Retour</Button>
      </div>
      <div style={{width: "100%", height: "85vh"}}>
        <FlipBook pdf={Gazette}/>
      </div>
    </div>
  );
};

export default GazetteView;