import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Icon, DemoModal } from "../../lib/components";
import { ArrowLeft } from "react-feather";
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import useAteliers from "../../hooks/useAteliers";
import styles from "../../assets/scss/pages/ateliers.module.scss";
import { VideoTuto } from "lib/components/YoutubeModal";
import useUI from "@/hooks/ui.hook";

const Router = () => {
  const { ateliers } = useAteliers();
  const [routes, setRoutes] = useState([]);
  const history = useHistory();
  const match = useRouteMatch();

  const calcRoutes = (_routes, _ateliers, baseRoute) => {
    if (_ateliers.link) {
      _routes.push({
        route: `${baseRoute}/link`,
        db: _ateliers,
        component: (p, r) => <>
          <div className={styles.controls}>
            <Icon icon={ArrowLeft} size={40} onClick={() => history.goBack()} />
          </div>
          <a href={r.db.link} target="_blank" rel="noreferrer">Mode plein écran</a>
          <iframe src={r.db.link} style={{ width: "100%", minHeight: 800 }}>
          </iframe>
        </>
      });
      return _routes;
    }
    _routes.push({
      route: `${baseRoute}`,
      db: _ateliers,
      component: (p, r) => <>
        <>
          <div className={styles.controls}>
            <Icon icon={ArrowLeft} size={40} onClick={() => history.goBack()} />
          </div>
          {Object.keys(r.db).map(r2 => r.db[r2])[0].link ?
            <Card>
              <h5>{r.route.split("/")[r.route.split("/").length - 2]}</h5>
              <div style={{ display: "flex", gap: 10 }}>
                {Object.keys(r.db).map(r2 => ({ title: r2, data: r.db[r2] })).map(r3 => (
                  <Link to={`${p.location.pathname}/${r3.title}/link`} className={styles.link} style={{ flex: 1 }} key={r3.title}>
                    <Card style={{ borderColor: "#F2994A", flex: 1, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                      <h6 style={{ color: "#F2994A", fontWeight: 600, fontSize: 24, }}>{r3.title}</h6>
                    </Card>
                  </Link>
                ))}
              </div>
            </Card>
            :
            <div style={{ display: "flex", flexDirection: "column", gap: 10, width: "100%" }}>
              {Object.keys(r.db).map(link =>
                <>
                  {Object.keys(r.db[link])[0] === "comingSoon" ?
                    <div style={{ flex: 1 }} key={`${p.location.pathname}/${link}`}>
                      <Card>
                        <h5>{link}</h5>
                        <div style={{ display: "flex", gap: 10 }}>
                          {Object.keys(r.db[link]).filter(i => i !== "comingSoon").map(r2 => <>
                            <Card style={{ borderColor: "#F2994A", flex: 1, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                              <h6 style={{ color: "#F2994A", fontWeight: 600, fontSize: 24, marginBottom: 0 }}>{r2}</h6>
                              <p>A venir: {Object.keys(r.db[link][r2]).length * 2} ateliers</p>
                            </Card>
                          </>)}
                        </div>
                      </Card>
                    </div>
                    :
                    <div style={{ width: "100%" }} key={`${p.location.pathname}/${link}`}>
                      <Card>
                        <h5>{link}</h5>
                        <div style={{ display: "flex", gap: 10 }}>
                          {Object.keys(r.db[link]).map(r2 => <>
                            <Link to={`${p.location.pathname}/${link}/${r2}`} className={styles.link} style={{ flex: 1 }} key={r2}>
                              <Card style={{ borderColor: "#F2994A", flex: 1, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", position: "relative" }}>
                                <h6>{r2}</h6>
                                {!r2.includes("Thème") && r2 !== "link" &&
                                  <span style={{ position: "absolute", top: -1, right: -1, color: "white", backgroundColor: "#F2994A", borderRadius: 15, padding: "0px 18px", borderBottomRightRadius: 0, borderTopLeftRadius: 0 }}>
                                    {Object.keys(r.db[link][r2]).length * 2}
                                  </span> || <></>}
                              </Card>
                            </Link>
                          </>)}
                        </div>
                      </Card>
                    </div>
                  }
                </>
              )}
            </div>
          }
        </>
      </>
    });
    Object.keys(_ateliers).map(i => _routes = calcRoutes(_routes, _ateliers[i], `${baseRoute}/${i}`));
    return (_routes);
  };

  useEffect(() => {
    setRoutes(calcRoutes([], ateliers, match.path));
  }, []);

  return (
    <Switch>
      {routes.map((r) =>
        <Route exact path={r.route}
          key={r.route}>
          {(p) => r.component ?
            r.component(p, r) : <>{JSON.stringify(r)}</>
          }
        </Route>
      )}
      <Route path="*">404</Route>
    </Switch>
  );
};

const Ateliers = () => {
  const [ui] = useUI();

  return (
    <Container><Row><Col lg={12}>
      {ui?.establishment?.demo === true ?
        <DemoModal type="ateliers"></DemoModal>
        : null}
      <VideoTuto url="https://youtu.be/W18_85zuhZ8" />
      <Router />
    </Col></Row></Container>
  );
};

export default Ateliers;