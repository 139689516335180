import React, { useState, useEffect } from "react";

import CreateEditNews from "./components/CreateEditNews";
import NewsCard from "./components/NewsCard";
import { PlusCircle, Clock } from "react-feather";
import { Button, Spinner, DemoModal } from "../../../lib/components";
import moment from "moment";
import styles from "../../../assets/scss/pages/news.module.scss";
import useNews from "../../../hooks/useNews";
import useActus from "resources/actus";
import { VideoTuto } from "lib/components/YoutubeModal";
import useRessources from "services";
import useFeature from "hooks/useFeature";
import BirthdayNews from "./components/BirthdayNews";
import { useHistory } from "react-router-dom";
import useUI from "@/hooks/ui.hook";


const News = () => {
  const [ui] = useUI();
  const dataEtab = useRessources({ ressource: "Actus", handler: "getActusForEtab_rt" });
  const dataNatio = useRessources({ ressource: "Actus", handler: "getActusForNational_rt" });
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [preFilledData, setPreFilledData] = useState(null);
  const [type, setType] = useState("all"); //all,published, draft, programmed
  const [actuCate, setActuCate] = useState([]); // TOUTES les notifications affiché dans une catégorie (toutes,service...)
  const history = useHistory();
  const isSavePublish = useFeature("savePublishNews");

  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const _data = [...dataEtab?.data ?? [], ...dataNatio?.data ?? []];

    setData(_data);

    let actuToShow = _data;
    if (type === "published") {
      actuToShow = _data.filter((item) => {
        return item.isProgrammed === false && item.isPublished === true || !item.isPublished && item.isPublished !== false;
      });
    } else if (type === "programmed") {
      actuToShow = _data.filter((item) => {
        return item.isProgrammed === true && item.isPublished === false;
      });
    } else if (type === "draft") {
      actuToShow = _data.filter((item) => {
        return item.isProgrammed === false && item.isPublished === false;
      });
    }
    //on remet les filtres à ceux d'origine qd on change de page
    setActuCate(actuToShow);
  }, [dataEtab.data, dataNatio.data, type]);

  const onClickNews = (_item) => {
    setItemSelected(_item);
    setIsOpen(true);
  };

  const changeCateogrieOfNotification = (cate) => {
    setType(cate);

  };

  if (!data) return <Spinner />;

  return (
    <div className={styles.container}>
      {ui?.establishment?.demo === true ?
        <DemoModal type="news"></DemoModal>
        : null}

      <div style={{ marginBottom: 18 }}>
        {isSavePublish && <div style={{ position: 'absolute', right: 160, marginTop: 0, display: "flex", gap: 5, cursor: "pointer", alignItems: "center" }}>
          <Clock onClick={() => history.push("/dashboard/waitingList")} color="#300438" size={32} />
          Programmation
        </div>}
        <VideoTuto url="https://youtu.be/3_i0XoifFxU" /*preview={animCard}*/ />
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <div className={`${styles.button} ${type === "all" ? styles.active : ""}`} onClick={() => changeCateogrieOfNotification("all")}>Toutes</div>

          {useFeature("savePublishNews") ? <>
            <div className={`${styles.button} ${type === "published" ? styles.active : ""}`} onClick={() => changeCateogrieOfNotification("published")}>Publiées</div>
            <div className={`${styles.button} ${type === "programmed" ? styles.active : ""}`} onClick={() => changeCateogrieOfNotification("programmed")}>Programmées</div>
            <div className={`${styles.button} ${type === "draft" ? styles.active : ""}`} onClick={() => changeCateogrieOfNotification("draft")}>Brouillon</div>
          </> : null}


        </div>

      </div>


      <div className={styles.body}>
        <BirthdayNews setIsOpen={setIsOpen} setPreFilledData={setPreFilledData} />

        <div className={styles.createCard} onClick={() => setIsOpen(true)}>
          <PlusCircle color="#4A0956" size={52} />
          <span>Créer une actualité</span>
        </div>
        {actuCate.filter(i => moment(i.date.toDate()).isBetween(moment().startOf("month"), moment().endOf("month")))
          .sort((a, b) => moment(b.date.toDate()).format("YYYYMMDD") - moment(a.date.toDate()).format("YYYYMMDD"))
          .map((item) => (
            <NewsCard key={item.uid} item={item} onClick={onClickNews} />
          ))}
        {showAll ? actuCate.filter(i => !moment(i.date.toDate()).isBetween(moment().startOf("month"), moment().endOf("month")))
          .sort((a, b) => moment(b.date.toDate()).format("YYYYMMDD") - moment(a.date.toDate()).format("YYYYMMDD"))
          .map((item) => (
            <NewsCard key={item.uid} item={item} onClick={onClickNews} />
          )) : null}
      </div>
      {actuCate.filter(i => !moment(i.date.toDate()).isBetween(moment().startOf("month"), moment().endOf("month"))).length > 0 && !showAll ?
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Button color="primary" onClick={() => setShowAll(true)}>Voir les anciennes actus</Button>
        </div>
        : null}
      <CreateEditNews
        item={itemSelected}
        preFilledData={preFilledData}
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(false);
          setItemSelected(null);
        }}
      />
    </div>
  );
};

export default News;
