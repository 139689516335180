import React, { useState, useEffect } from "react";
import useUI from "../../../../hooks/ui.hook";
import { VideoTuto } from "lib/components/YoutubeModal";
import { DemoModal } from "@/lib/components";

import firebase from "firebase";

import styles from "../../../../assets/scss/pages/reservation/menu.module.scss";
import moment from "moment"; 

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Details from "./Details";
import Resume from "./Resume";
import ModalWeek from "./ModalWeek";

import usePlanningReservation,{Provider} from "./contextReservationPlanning";

//import {updateUser} from "../dataManager";
// const firestore = firebase.firestore;

const ReservationPlanningContent = () => {
  const [ui] = useUI();
  const [ctx] = usePlanningReservation();
  if(!ctx.selectedWeek)return <></>;

  return (
    <div className={styles.menu}>
      {ui?.establishment?.demo === true ?
        <DemoModal type="reservationanimation"></DemoModal>
        : null}
      <div className={styles.weekSelector}>
        <FontAwesomeIcon
          className={styles.icon}
          icon={faArrowLeft}
          onClick={() => {
            ctx.changeWeek(-1);
          }}
        />
        <div className={styles.weekText}>
          {ctx.selectedWeek.clone().format("DD MMMM YYYY")} - {ctx.selectedWeek.clone().endOf("week").format("DD MMMM YYYY")}
        </div>
        <FontAwesomeIcon
          className={styles.icon}
          icon={faArrowRight}
          onClick={() => {
            ctx.changeWeek(1);
          }}
        />
      </div>
      <Resume/>
      <Details/>
      <ModalWeek/>
    </div>
  );
};


const ReservationPlanning = () => {
  return(
    <Provider>
      <ReservationPlanningContent/>
      <VideoTuto url="https://youtu.be/1P61Re4pRx4" />
    </Provider>
  );
};
export default ReservationPlanning;
